<template>
  <div class="products">
    <PasswordLock :locked="passwordLocked"/>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('收銀結餘') }}</h2>
      </div>
      <div class="seat-list" v-if="!passwordLocked">
        <div class="top-balance-info">
          <div class="card total-balance">
            <div class="card-body">
              <h5 class="card-title">總銷售額</h5>
              <p class="card-text">$480</p>
            </div>
          </div>
          <div class="card total-balance">
            <div class="card-body">
              <h5 class="card-title">總訂單數</h5>
              <p class="card-text">1</p>
            </div>
          </div>
        </div>
        <div class="bottom-report">
          <div class="charts-cards">
            <div class="chart-inner">
              <h3>訂單數</h3>
              <TotalOrders />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import PasswordLock from '@/components/PasswordLock.vue';
import TotalOrders from '@/components/report/TotalOrders.vue';

export default {
  name: 'Seats',
  components: {
    TotalOrders,
    PasswordLock,
  },
  data(){
    return {
      seats: [],
    }
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
   passwordLocked: state => state.passwordLocked,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.seat-image{
  margin-left: auto;
  margin-right: auto;
  max-width: 30%;
  display: block;
}
.seat-list{
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 50px;
}
.top-balance-info{
  display: flex;
  .card{
    margin-right: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.bottom-report{
  margin-top: 50px;
}
</style>
