<template>
    <el-dialog title="經理功能密碼保護" :visible.sync="passwordLocked">
        <div class="form-group">
            <label for="password">{{ $t('Password') }}</label>
            <input type="password" class="form-control" id="password" v-model="inputPassword">
        </div>
        <button type="submit" class="btn btn-primary" @click="submitPassword">解鎖</button>
    </el-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'PasswordLock',
    methods:{
        submitPassword(){
            if(this.inputPassword === this.password){
                this.unlock();
            }
        },
        ...mapActions(['unlock']),
    },
    data() {
        return {
            inputPassword: '',
            password: 'testtvp',
        }
    },
    computed: mapState({
        langcode: state => state.langcode,
        apiUrl: state => state.apiUrl,
        passwordLocked: state => state.passwordLocked,
    }),
}
</script>

<style scoped lang="scss"></style>