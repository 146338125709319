import { render, staticRenderFns } from "./PasswordLock.vue?vue&type=template&id=0c32f4dd&scoped=true&"
import script from "./PasswordLock.vue?vue&type=script&lang=js&"
export * from "./PasswordLock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c32f4dd",
  null
  
)

export default component.exports